<template>
  <div class="main">
    <!-- <div class="top" :style="{backgroundImage: 'url(' + obj.image+')',backgroundSize: '100% 100%'}"> -->
    <div class="top" style="position: relative;" @mouseover="mouseover()" @mouseleave="mouseout()">
      <img :src="obj.image" alt="" style="position: absolute;width: 100%;height: 100%;border-radius: 6px;z-index: 11;">
      <div style="position: absolute;right:5px;bottom:5px;z-index: 12;color: whitesmoke;font-size: 12px;background: rgba(0, 0, 0, .7);padding: 1px 2px;
    border-radius: 4px;">{{ obj.duration }}</div>
      <!-- 播放图标 开始 -->
      <div class="play-wrap" v-if="show == true">
        <div class="play" @click="pause()"
             v-if="$store.state.musicInfo.playStatus && obj.musicId == $store.state.musicInfo.musicId">
          <Icon name="icon-7key-music24gf-pause2" class="icon-play" color="#3370FF"/>
        </div>
        <div class="play" @click="play()"
             v-else>
          <Icon name="icon-7key-musicplayfill" class="icon-play" color="#3370FF"/>
        </div>
      </div>
      <!-- 播放图标 结束 -->
    </div>
<!--    <div class="bottom">-->
<!--      <div class="title-time">-->
<!--        <div class="title">{{ obj.title }}</div>-->
<!--        <div class="time">{{ obj.duration }}</div>-->
<!--      </div>-->
<!--      <div class="sing-collect">-->
<!--        <div @click="geci(obj)" class="sing">-->
<!--          <div class="sing_txt">{{$t('musicaichakangeci')}}</div>-->
<!--          <div class="sing-icon"><i class="el-icon-view" /></div>-->
<!--        </div>-->
<!--        <div @click="xiazai(obj)" class="collect">-->
<!--          <div class="down_txt">{{$t('musicaixiazaiyinyue')}}</div>-->
<!--          <div class="down-icon"><i class="el-icon-download" /></div>-->
<!--          <Icon v-if="themeStyle == 'default'" name="icon-7key-musicshoucang" color="#666666" width="18" height="18"></Icon>-->
<!--          <Icon v-if="themeStyle == 'default'" name="icon-7key-musicshoucang" color="#666666" width="18" height="18"></Icon>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "MusicAIBottom",
  props: {
    obj: {
      type: Object,
      default() {
        return {}
      }
    },
	type: {
		type: String,
		default: ''
	}
	
  },
  data() {
    return {
      show: false,//控制播放图标显示 false 不显示  true 显示
      themeStyle: '',// 主题 default默认主题 dark 深色主题
    }
  },
  mounted() {
    const that = this;
    window.addEventListener("setItemEvent", function (e) {
      if((e.newValue === 'dark') || (e.newValue === 'default')){
        console.log(4545454545 ,e.newValue)
        that.themeStyle = e.newValue;
      }
      // // console.log(e.newValue);
      // that.themeStyle = e.newValue;
    });
    this.themeStyle = localStorage.getItem("theme");
  },
  created() {
    // this.themeStyle = localStorage.getItem("theme")
  },
  methods: {
    // 下载
    xiazai(obj) {
      this.$emit('xiazai', obj)
    },
    // 查看歌词
    geci(obj) {
      this.$emit('geci', obj)
    },
    // 跳转到详情页
    toDetail(id) {
      console.log(id, 'id')
      this.$router.push({
        path: '/musicianList/musicianPersonalCenter',
        query: {
          creator_id: id
        }
      })
      // this.$emit('toDeatil',id)
      // this.$router.push({
      // 	path: '/MusicianPersonalCenterFinished'
      // })
    },
    // 鼠标移入事件
    mouseover() {
      // console.log('mouseover')
      this.show = true
    },
    // 鼠标移出事件
    mouseout() {
      // console.log('mouseout')
      this.show = false
    },
    play() {
      this.$set(this.obj, 'playStatus', true)
      this.show = false
      this.$store.commit('changeMusicInfo', this.obj)
      console.log('播放:', this.obj)
	  this.$store.state.playShow = true;
	  console.log('/////////////')
    },
    pause() {
      this.$set(this.obj, 'playStatus', false)
      this.show = false
      this.$store.commit('changeMusicInfo', this.obj)
      console.log('暂停:', this.obj)
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  // width: 220px;
  //width: 100%;
  //height: 296px;
  // height: 356px;
  
  // height: auto;
  //background: @recommendMusicianListBGColor;
  color: @recommendMusicianListTextColor;
  border-radius: 6px;
  //cursor: pointer;

  .top {
    // background-image: url('../../assets/images/recommend/song-bg.jpg');
    // background-size: 100% 100%;
    // width: 220px;
    width: 100px;
    height: 100px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .play-wrap {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      z-index: 33;
      display: flex;
      align-items: center;
      justify-content: center;

      .play {
        color: #3370FF;
        font-size: 40px;
        width: 72px;
        height: 72px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.8);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: RGBA(169, 173, 175, 0.8);
        z-index: 33;
		.icon-play{
			margin-top: -6px;
		}
      }
    }


  }

  .bottom {
    display: flex;
    flex-direction: column;
	// padding-bottom: 26px;

    .title-time {
      display: flex;
      justify-content: space-between;
      margin-top: 6px;

      .title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 500;
        width: 70%; // (一定要加宽度）
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; // 溢出用省略号
        // white-space: nowrap; //溢出不换行
        -webkit-line-clamp: 1; //控制显示的行数
        -webkit-box-orient: vertical; // 从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式)
        display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
      }

      .time {
        margin-right: 12px;
        color: @recommendMusicianListTitleColor;
        font-size: 12px;
      }
    }

    .sing-collect {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 36px;
      align-items: center;
      margin-left: 16px;
      margin-top: 10px;

      .sing {
        margin-right: 12px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        .sing_txt {
          display: flex;
          font-size: 12px;
          margin-right: 2px;
          cursor: pointer;
        }
        .sing-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
        }

      }
      .collect {
        margin-right: 12px;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-content: center;

        .down_txt {
          display: flex;
          font-size: 12px;
          margin-right: 2px;
          cursor: pointer;
        }
        .down-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          cursor: pointer;
        }
        img {
          width: 18px;
          height: 18px;

        }
      }
    }
  }
}
</style>
